<template>
  <v-row
    justify="center"
    class="mt-12"
  >
    <v-slide-y-transition appear>
      <BaseMaterialCard
        color="accent"
        light
        max-width="100%"
        width="400"
        class="px-5 py-3"
      >
        <template v-slot:heading>
          <div class="text-center">
            <v-img
              src="@/assets/logo_white.png"
              class="ma-2"
            />
          </div>
        </template>

        <v-card-text class="text-center">
          <v-form>
            <p
              justify="start"
              class="text-subtitle-1 font-italic"
            >
              Reset Password
            </p>

            <p
              v-if="error"
              class="red--text font-weight-bold text-left"
            >
              <v-icon
                color="red"
                class="mr-1"
              >
                mdi-alert-circle-outline
              </v-icon>
              {{ error }}
            </p>

            <v-text-field
              v-model="password"
              color="secondary"
              prepend-icon="mdi-lock-outline"
              :error-messages="passwordErrors"
              required
              name="password"
              label="New Password"
              type="password"
              :success="passwordSuccess"
              error-count="3"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              @keyup.enter="submit()"
            />

            <v-text-field
              v-model="confirmPassword"
              color="secondary"
              :prepend-icon="confirmSuccess ? 'mdi-check-all' : 'mdi-lock-question'"
              :error-messages="confirmPasswordErrors"
              required
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              :success="confirmSuccess"
              @input="$v.confirmPassword.$touch()"
              @blur="$v.confirmPassword.$touch()"
              @keyup.enter="submit()"
            />

            <pages-btn
              large
              color="secondary"
              depressed
              class="v-btn--text success--text mt-4"
              @click="submit()"
            >
              <v-icon left>
                mdi-lock-reset
              </v-icon>Reset Password
            </pages-btn>
          </v-form>
        </v-card-text>
      </BaseMaterialCard>
    </v-slide-y-transition>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import {
  atLeastOneDigit,
  atLeastOneUppercase,
  atLeastOneLowercase,
  atLeastOneSpecial
} from '@/shared/validators'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('auth')

export default {
  name: 'PasswordReset',

  components: {
    PagesBtn: () => import('../components/Btn')
  },

  mixins: [validationMixin],

  validations: {
    password: {
      required,
      minLength: minLength(8),
      atLeastOneDigit,
      atLeastOneUppercase,
      atLeastOneLowercase,
      atLeastOneSpecial
    },
    confirmPassword: { sameAs: sameAs('password') }
  },

  props: {
    code: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      default: null
    }
  },

  data: () => ({
    error: null,
    message: null,
    password: null,
    confirmPassword: null
  }),

  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required')
      !this.$v.password.minLength &&
        errors.push('New Password must be at least 8 characters')
      !this.$v.password.atLeastOneLowercase &&
        errors.push('New Password must have at least 1 lowercase letter')
      !this.$v.password.atLeastOneUppercase &&
        errors.push('New Password must have at least 1 uppercase letter')
      !this.$v.password.atLeastOneDigit &&
        errors.push('New Password must have at least 1 number')
      !this.$v.password.atLeastOneSpecial &&
        errors.push('New Password must have at least 1 special character')
      return errors
    },
    confirmPasswordErrors () {
      const errors = []
      if (!this.$v.confirmPassword.$dirty) return errors
      !this.$v.confirmPassword.sameAs && errors.push('Passwords must match')
      return errors
    },
    passwordSuccess () {
      return this.passwordErrors.length === 0 && this.password !== null
    },
    confirmSuccess () {
      return (
        this.confirmPasswordErrors.length === 0 && this.confirmPassword !== null
      )
    }
  },

  methods: {
    ...mapActions(['passwordReset']),
    async submit () {
      this.error = null
      this.message = null

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const data = await this.passwordReset({
        code: this.code,
        userId: this.userId,
        newPassword: this.password
      })

      if (data.errorResponse) {
        this.error = data.errorResponse
        return
      }

      this.$router.push({ name: 'AppLogin' })
    }
  }
}
</script>
